<template>
  <section id="team" class="our_team section-padding">
    <div class="container">
      <div class="row text-center">
        <div class="col-sm-12 offset-md-2 col-md-8 offset-md-2 ">
          <p class="home-text mb-4"><b>{{ $t("components.agent_text.text") }}</b></p>
        </div>
        <div class="col-sm-12 offset-md-3 col-md-6 offset-md-3 ">
          <ContactForm/>
        </div>
        
      </div>
      
    </div>
    <!-- <div class="container" v-if="agents.length > 0">
      <div class="row text-center justify-content-center">
        <Preloader v-if="!agents" />

        <AgentCard
          v-else
          v-for="(item, i) in agents"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :agent="item"
        />
      </div>
    </div> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import AgentCard from "@/components/layout/AgentCard.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
export default {
  components: {
    Preloader,
    AgentCard,
    ContactForm
  },
  computed: {
    ...mapGetters({
      agents: "_getTeam",
      error: "_getError",
    }),
  },
  created() {
    this.$store.dispatch("getTeam");
  },
};
</script>

<style scoped>

.justify-content-center{
  justify-content: center !important;
}
</style>