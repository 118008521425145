<template>
  <section>
    <section id="contact" class="contact-us section-padding" style='background-image: url("/img/homecarrousel/render4_rooftop.jpg");'>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7 col-sm-6 col-xs-12">
            <img src="/img/Masterpiece.png" class="img-fluid-masterpiece col-4">
            <img class="img-fluid arrow-white col-4" src="/img/white-arrow.png" />
            
            <div class="col-md-10 text-center text-left-side">
              <span class="text1">{{ $t('components.ContactForm2.text1')}} <br>
              {{ $t('components.ContactForm2.text2')}} <br>
              {{ $t('components.ContactForm2.text3')}}</span>
              
              <br>
              <br><br><br><br>
              <span class="text2"> {{ $t('components.ContactForm2.quote')}}</span>
            </div>
          </div> 
          <div class="col-md-5 col-sm-6 col-xs-12"> 
            <div class="text-center">
              <img src="/img/Caribique_logo_condominium.png" class="img-fluid-cari">
            </div>
            
            <ContactForm2/>
            <div class="redes text-center ">
              <!-- <a class="instagram" href="" target="_blank"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                </svg> 
              </a> -->
              <!-- <a href="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
              </a> -->
              
              <a class="messenger" href="http://m.me/100389219307939" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-messenger" viewBox="0 0 16 16">
                <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/>
                </svg>
              </a>
              <a class="whatsapp" href="https://api.whatsapp.com/send?phone=529843105454" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
              </a>
            </div>
          </div> 
        </div> 
      </div>
    </section>
    <section class="mt-5 mb-5 ml-2 mr-2">
      <CaribiqueCarousel/>
    </section>
  </section>

</template>
<script>
import ContactForm2 from '../../components/ContactForm2.vue'
import CaribiqueCarousel from '../../components/carousel/CaribiqueCarousel.vue'
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  name: "app",
  components: {
    ContactForm2,
    CaribiqueCarousel,
    LocaleSwitcher
  },
    created(){
    // console.log('local antes', this.$i18n.locale)
    if (this.$i18n.locale = 'es'){
        this.$i18n.locale = 'eng';
        localStorage.setItem("eng", this.$i18n.locale);
    }
    // console.log('local despues', this.$i18n.locale)
  },
}
</script>
<style>
#contact {
  /* background-position: center center; */
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
}
#contact .redes svg{  
  font-size: 50px;
  width: 30px;
  height: 70px;  
  
}
#contact .redes a{
  margin: 0px 1rem;
  background: #000000b3;
  padding: 1.9rem 1.2rem;
  border-radius: 10rem;
  color: #fff;
  transition: all 0.3s ease 0s;
}
#contact .redes a.whatsapp:hover{
  background: #51cd5e;
}
#contact .redes a.instagram:hover{
  background: rgb(184,50,181);
  background: linear-gradient(270deg, rgba(184,50,181,1) 22%, rgba(243,77,87,1) 80%, rgba(254,181,79,1) 98%); 
}
#contact .redes a.messenger:hover{
  background: rgb(230,74,151);
  background: linear-gradient(173deg, rgba(230,74,151,1) 22%, rgba(45,121,247,1) 99%);
}
#contact .text1{
  font-weight: bold;
  font-size: 3rem;
  color:#ffffff;
}
#contact .text2{
  color:#f2f9fe;
  font-size: 350%
}
#contact .img-fluid-masterpiece{
  width: 59vw;
}
#contact .img-fluid-cari{
  max-width: 100%;
}
#contact .arrow-white{
  position: absolute;
  right: 25rem;
  bottom: 5rem;
  width: 52vw;
  transform: rotate(86deg);
}
 #contact .text-left-side{
    margin-top:1rem;
  }
  @media (min-width:768px){
    #contact .redes a{
    margin: 0px .5rem;
    }

  #contact .arrow-white{
      position: absolute;
      right: 1rem;
      bottom: 39rem;
      width: 14vw;
      transform: inherit;
    }
    #contact .img-fluid-masterpiece{
      width: 39vw;
    }
    #contact .text1{
    font-weight: bold;
    font-size: 2.5rem;
    color:#ffffff;
    }
    #contact .text2{
      color:#f2f9fe;
      font-size: 300%
    }
  }
@media (min-width:991px){
  #contact .redes a{
    margin: 0px 3rem;
  }
  #contact .img-fluid-masterpiece {
    width: 25vw;
  }
  #contact .img-fluid-cari {
    max-width: 23vw;
  }
  #contact .arrow-white{
    position: absolute;
    right: 1rem;
    bottom: 39rem;
    width: 14vw;
    transform: inherit;
  }
  #contact .text-left-side{
    margin-top:7rem;
  }
}

</style>
