<template>
  <section id="homeCarousel">
    <div class="watr"> 
      <button @click="prev()" class="btn s-prev"> <i class="fas fa-chevron-left"></i></button>
      <button @click="next()" class="btn s-next"> <i class="fas fa-chevron-right"></i></button>
      <slick ref="slick" :options="slickOptions">
         <div class="render1">
          <img class="vertical-img" src="/img/homecarrousel/render1_edificio.jpg" alt="Edificio-European-Quality-Development-Edificio">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div> 
        <div class="render2">
          <img src="/img/homecarrousel/render2_DEP_C_interior.jpg" alt="Edificio-DepartamentoInterior-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div> 
        <div class="render2">
          <img src="/img/homecarrousel/render3_DEP_A.jpg" alt="Edificio-Departamento-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
        
        <div class="render4">
          <img src="/img/homecarrousel/render4_rooftop.jpg" alt="Edificio-Rooftop-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
        <div class="render2">
          <img src="/img/homecarrousel/render5_rooftop_pudorys.jpg" alt="Edificio-RooftopPYS-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
        <div class="render2">
          <img src="/img/homecarrousel/render6_recepción.jpg" alt="Edificio-Recepción-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
        <div class="render2">
          <img src="/img/homecarrousel/render7_jacuzzi.jpg" alt="Edificio-Jacuzzi-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
        <div class="render2">
        <img src="/img/homecarrousel/render8_DEP_C.jpg" alt="Edificio-DEP-European-Quality-Development">
          <div class="watermark" :style="'background-image:url(/img/Caribique_logo_condominium.png); '"> </div>
        </div>
      </slick>

        <!-- <div
          v-if="info.logoClean"
          class="watermark cc_pointer d-md-none d-lg-block"
          :style="'background-image:url(' + info.logoClean + '); '"
      ></div> -->
    </div>
  </section>
</template>

<script>
import { VueAgile } from "vue-agile";
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import slick from 'vue-slick'
import '/node_modules/slick-carousel/slick/slick.css'
export default {
  components: {
    VueAgile,
    Preloader,
    slick,
  },
   data() {
    return {
      slickOptions:{
        dots: true,
        autoplay: true,
        fade: true,
        arrows:false,
        slidesToShow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            },

            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,
              }
            },
          ]
      },
    };
  },
  //   computed: {
  //   ...mapGetters({
  //     info: "_getInfoCompany",
  //   }),
  // },
  //   created() {
  //   this.origin = this.$route.name;
  // },
  methods: {
    next() {
        this.$refs.slick.next();
    },
    prev() {
        this.$refs.slick.prev();
    },
  },
};
</script>

<style>
  .render1 .watermark{
      height: 40vh;
      margin-top: -87vh;
      opacity: 1;
  }
  .render1 .watermark{
    background-position: -6% 0%;
  }
  .render1 .watermark {
    height: 50vh !important;
  }
  .render1 .watermark{
    max-height: 50%;
    bottom: 43% !important;
  }
  .render2 .watermark{
      height: 40vh;
      margin-top: -87vh;
      opacity: 1;
  }
  .render2 .watermark{
    background-position: 2% 0%;
  }
  .render2 .watermark {
    height: 50vh !important;
  }
  .render2 .watermark{
    max-height: 50%;
    bottom: 43% !important;
  }
  .render4 .watermark{
      height: 40vh;
      margin-top: -87vh;
      opacity: 1;
  }
  .render4 .watermark{
    background-position: 90% 0%;
  }
  .render4 .watermark {
    height: 50vh !important;
  }
  .render4 .watermark{
    max-height: 50%;
    bottom: 43% !important;
  }
  #homeCarousel .slick-slide img{
    height: 59vh;
    object-fit: cover;
    width: 100%;
  }
@media only screen and (min-width: 767px) {
  .render1 .watermark{
    height: 40vh;
    margin-top: -87vh;
  }
  .render1 .watermark{
    background-position: 7% 0%;
  }
  .render1 .watermark {
    height: 50vh !important;
  }
  .render1 .watermark{
    max-height: 20%;
    bottom: 73% !important;
  }
  .render2 .watermark{
    background-position: 7% 0%;
  }
  .render2 .watermark {
    height: 50vh !important;
  }
  .render2 .watermark{
    max-height: 20%;
    bottom: 73% !important;
  }
  .render4 .watermark{
    background-position: 90% 0%;
  }
  .render4 .watermark {
    height: 50vh !important;
  }
  .render4 .watermark{
    max-height: 20%;
    bottom: 73% !important;
  }

}
@media only screen and (min-width: 991px) {
  .vertical-img{
  -o-object-fit: contain !important;
  object-fit: contain !important;
  -o-object-position: center !important;
  object-position: center !important;
  height: 89vh !important;
  width: 100% !important;
  filter: alpha(opacity=90) !important;
  opacity: 1 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:url("/img/render1_edificioblur2.jpg");
}
  #homeCarousel .slick-slide img{
  height: 89vh;
  object-fit: cover;
  width: 100%;
  }
  #homeCarousel .s-prev, #homeCarousel .s-next, #homeCarousel .s-prev:hover, #homeCarousel .s-next:hover {
  background-color: #0c0e20 !important;
  background: transparent !important;
  top: 12%;
  }
  .render1 .watermark{
      height: 40vh;
      margin-top: -87vh;
  }
  .render1 .watermark{
      height: 100%;
      background-size: 20%;
      background-position: 38% 0%;
  }
  .render2 .watermark{
      height: 40vh;
      margin-top: -85vh;
  }
  .render2 .watermark{
      height: 100%;
      background-size: 20%;
      background-position: 7% 0%;
  }
  .render4 .watermark{
      height: 40vh;
      margin-top: -85vh;
  }
  .render4 .watermark{
      height: 100%;
      background-size: 20%;
      background-position: 90% 0%;
  }
}

@media only screen and (max-width: 991px) {
  #homeCarousel .image-slide,
  #homeCarousel .image-slide-back,
  #homeCarousel .watermark {
    height: 14vh;
  }
}
@media only screen and (max-width: 767px) {
  #homeCarousel .watermark {
    background-size: auto 80px !important;
  }
  #homeCarousel .watermark {
    max-height: 50%;
    background-size: auto 150px;
    bottom: 29%;
  }
}






#homeCarousel .slide-img-overlay {
  padding: 0 !important;
}
#homeCarousel .btn{
  box-shadow: inherit !important;
  border: inherit !important;
}
#homeCarousel .s-prev, #homeCarousel .s-next, #homeCarousel .s-prev:hover,#homeCarousel .s-next:hover {
    background-color: #0c0e20 !important;
    background: transparent !important;
    top: 42%;
}
/* @media (min-width: 768px) { 
  #homeCarousel .s-prev, #homeCarousel .s-next, #homeCarousel .s-prev:hover,#homeCarousel .s-next:hover {
      background-color: #0c0e20 !important;
      background: transparent !important;
      top: 11%;
  }
} */
@media (min-width: 992px) {
    #homeCarousel .s-prev, #homeCarousel .s-next, #homeCarousel .s-prev:hover,#homeCarousel .s-next:hover {
      background-color: #0c0e20 !important;
      background: transparent !important;
      top: 12%;
  }
 }
@media (min-width: 1200px) { 
    #homeCarousel .s-prev, #homeCarousel .s-next, #homeCarousel .s-prev:hover,#homeCarousel .s-next:hover {
      background-color: #0c0e20 !important;
      background: transparent !important;
      top: 50%;
  }
 }
.watr{
  position: relative;

}
.s-prev{
    position: absolute;
    left: 2vw !important;
    z-index: 10001;
}
.s-next{
    position: absolute;
    right: 2vw !important;
    z-index: 10001;
}
.s-prev, .s-next i{
    color: #ffe064;
    font-size: 35px;
    text-shadow: 3px 1px 8px rgb(6, 5, 2) !important;
    animation: 1.5s icon infinite forwards;
}
@keyframes icon {
    0%,100%{
      transform: translate(0px);
    }
    50% {
      transform: translate(3px);
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 28px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    z-index: 10001;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 4px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button::before {
    font-family: 'slick';
    font-size: 25px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    /* content: '•'; */
    content: '━';
    text-align: center;
    opacity: .80;
    color: #fff;
    /*text-shadow: 3px 1px 8px rgb(6, 5, 2);*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li.slick-active button::before {
    /* text-shadow: 3px 1px 8px rgb(6, 5, 2); */
    color: #ffe064 ;
    content: '━';
    font-size: 25px;
    opacity: inherit;
    margin-top: -2px;
}
</style>
