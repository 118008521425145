<template>
  <nav class="navbar navbar-expand-lg navbar-light ">
    <div class="container">
      <div class="header-nav">
        <router-link id="left" to="/">
          <img
            v-if="info"
            class="home-logo"
            :src="info.logo"
            :alt="info.alt"
          />
        </router-link>
        <button
          id="right"
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      

      <div class=" navbar-collapse collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Home'}">{{ $t("layout.nav.home") }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'About'}">{{
              $t("layout.nav.about.title")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Properties'}">{{
              $t("layout.nav.properties")
            }}</router-link>
          </li>
          <!-- <li>
            <router-link to="/desarrollos">{{
              $t("layout.nav.developments")
            }}</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Agents'}">{{
              $t("layout.nav.agents")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Contact'}">{{
              $t("layout.nav.contact")
            }}</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/caribique-en">{{
              $t("layout.nav.caribique-en")
            }}</router-link>
          </li> -->
          <li class="nav-item">
            <LocaleSwitcher />
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components: {
    LocaleSwitcher,
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.home-logo {
  width: 15vw;
}
@media only screen and (max-width: 991px) {
  .home-logo {
    width: 69%;
  }
  .navbar-light .navbar-nav > .nav-item > .nav-link{
    padding-left: 0;
    padding-right: 0;
    padding-top: .9rem;
    padding-bottom: .9rem;
  }
    /* .navbar-collapse.collapse {
    display: inherit !important;
    height: inherit  !important;
    padding-bottom: inherit !important;
    overflow: inherit  !important;
} */
#left {
  float: left;
  width: 60%;
}

#right {
  float: right;
}
 .collapse {
  margin-left: 1%;

 }
}

@media (min-width: 768px) {
  .navbar-nav li {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  /* .navbar-collapse.collapse {
    display: inherit !important;
    height: inherit  !important;
    padding-bottom: inherit !important;
    overflow: inherit  !important;
} */

}
@media (min-width:991px){
  .collapse {
    margin-left: 4%;
  }
}
/* @media (min-width: 1200px){
  .collapse {
    margin-left: 13%;
  }
} */
.nav > li {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.navbar-nav li {
  color: #333 !important;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 15px;
  text-decoration: none;
}

.navbar-nav li:hover {
  color: #00aeef !important;
}

a {
  color: #333 !important;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s ease 0s;
}

a:hover {
  color: #252b5f !important;
}

.navbar {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0;
}

.navbar-light .navbar-toggler {
  border-color: none !important;
}




</style>
