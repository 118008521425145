<template>
  <section
    class="section-top color"
    style="background-image: url(/images/patterns/pattern2.jpg); background-size:cover;  background-position: center center;background-attachment:fixed;"
  >
    <div class="container">
      <div class="col-md-10 col-md-offset-1 col-xs-12 text-center center-text">
        <div
          class="section-top-title wow fadeInRight"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
          data-wow-offset="0"
        >
          <h1>{{$t(`${pageTitle}`)}}</h1>
          <ol class="breadcrumb">
            <li>
              <a href="/">{{ $t("routes.home.breadcrumb") }}</a>
            </li>
            <li class="active">{{$t(`${pageTitle}`)}}</li>
          </ol>
        </div>
      </div>
      <!--- END COL -->
    </div>
    <!--- END CONTAINER -->
  </section>
</template>

<script>
export default {
  props: {
    pageTitle: { type: String, required: true },
  },
};
</script>

<style scoped>
a{
  font-size: 15px !important;
  text-decoration: none;
}

li{
  font-size: 15px !important;
}

.breadcrumb {
    display: block;
    flex-wrap: wrap;
    padding: 8px 15px;
    margin-bottom: 1rem;
    list-style: none;
    
}
.section-top:before {
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: #171c48d4;
}

.center-text{
  top: -30px;
}
</style>