<template>
  <div class="col-md-4 col-sm-4 col-xs-12">
    <div class="single_team">
        <img
        v-if="agent.image"
        :src="agent.image ? agent.image : '/images/avatar.png'"
        :alt="`${agent.fullname}-img`"
        class="img-responsive"
      />
      <h3 v-if="agent.fullname">{{agent.fullname}}</h3>
      <p><a v-if="agent.cellphone" :href="'tel:' + agent.cellphone" target="_blank"> {{ agent.cellphone }}</a>  </p>
      <p><a v-if="agent.email" :href="'mailto:' + agent.email" target="_blank"> {{ agent.email }}</a> </p>
      <!-- <p>Co Founder</p> -->
      <!-- <ul class="list-inline">
        <li v-if="agent.tweet">
          <a :href="agent.tweet" target="_blank"><i class="fa fa-twitter"></i></a>
        </li>
        <li v-if="agent.fb">
          <a :href="agent.fb"><i class="fa fa-facebook"></i></a>
        </li>
        <li v-if="agent.linkedin">
          <a :href="agent.linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
        <li v-if="agent.instagram">
          <a :href="agent.instagram" target="_blank"><i class="fa fa-instagram"></i></a>
        </li>
        <li v-if="agent.gmas">
          <a :href="agent.gmas"><i class="fa fa-google-plus"></i></a>
        </li>
        <li v-if="agent.skypeid">
          <a :href="agent.skypeid"><i class="fa fa-skype"></i></a>
        </li>
      </ul> -->
      <ul class="list-inline ">
        <li >
          <a :href="agent.tweet" target="_blank"><i class="fa fa-twitter"></i></a>
        </li>
        <li>
          <a :href="agent.fb"><i class="fa fa-facebook"></i></a>
        </li>
        <li >
          <a :href="agent.linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
        <li >
          <a :href="agent.instagram" target="_blank"><i class="fa fa-instagram"></i></a>
        </li>
        <li >
          <a :href="agent.gmas"><i class="fa fa-google-plus"></i></a>
        </li>
        <li >
          <a :href="agent.skypeid"><i class="fa fa-skype"></i></a>
        </li>
      </ul>
    </div>
    <!--- END SINGLE TEAM -->
  </div>
  <!--- END COL -->
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    agent: {
      type: Object,
      required: false,
    },
  },
  computed: {
    formatBiography() {
      return this.agent.biografia.substring(0, 125) + "...";
    },
  },
  methods: {
    goToAgent() {
      //this.$store.dispatch('setAgentActive',this.agent);
      let nameAgentFilt = services.normalize(this.agent.fullname);
      let folio = this.agent.folio;
      this.$router.push({
        name: "Agent",
        params: {
          folio,
          nameAgentFilt,
        },
      });
    },
  },
};
</script>


<style scoped>
.img-responsive{
  padding: 0 120px;
}

a{
  color: #373737;
}

a:hover{
  color: #ef4b4a;
}
</style>