<template>
  <section class="template-property section-padding">
    <div class="container">
        <div class="row ">
          <div class="col-md-6 col-sm-12 col-xs-12 text-center">
            <div class="about-us-content">
              <h2>{{ info.companyName }}</h2>
              <p>
                {{ $t("pages.about.company.description") }}
              </p>
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.mision.title") }}</h2>
              <p>
                {{ $t("pages.about.mision.description") }}
              </p>
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.vision.title") }}</h2>
              <p>
                {{ $t("pages.about.vision.description") }}
              </p>
            </div>
            
        <div class="about-us-content mt-5">
          <router-link class="" :to="{name: 'Contact'}">{{ $t("pages.about.contact-button") }}</router-link>
        </div>
          </div>
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div class="about_img text-center">
              <img
                v-if="info.logoClean"
                class="logo mt-3 mb-0"
                :src="info.logoClean"
                alt="logo_web"
              />
            </div>
            <div class="about-us-content">
              <h2>{{ $t("pages.about.values.title") }}</h2>
              <ul>
                <li>
                  <i class="fa fa-check"></i
                  ><b>{{ $t("pages.about.values.example_one.val") }} </b> {{ $t("pages.about.values.example_one.description") }}
                </li>
                <li>
                  <i class="fa fa-check"></i
                  ><b>{{ $t("pages.about.values.example_two.val") }} </b> {{ $t("pages.about.values.example_two.description") }}
                </li>
                <li>
                  <i class="fa fa-check"></i
                  ><b>{{ $t("pages.about.values.example_three.val") }}</b> {{ $t("pages.about.values.example_three.description") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "app",
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
};
</script>

<style scoped>
.logo {
  display: inline-block;
  max-width: 45% !important;
  margin-top: auto;
}
.logo img {
  margin-top: 30%;
  margin-bottom: 20px;
  margin-left: -10px;
  max-width: 20px;
  max-height: 30px;
}

.about_img {
  margin-bottom: 30px;
}

.about-us-content {
  padding: 0 30px;
  align-content: center !important;
}
.about-us-content p, li{
  text-align: justify !important;
}


</style>
