<template>
  <div>
    <footer class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="footer_social">
              <ul >
                <li v-if="info.fb">
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.fb"
                    data-original-title="Facebook"
                    ><i class="fa-brands fa-facebook-f"></i></a>
                </li>
                <li v-if="info.tw">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.tw"
                    data-original-title="Twitter"
                    ><i class="fa-brands fa-twitter"></i></a>
                </li>
                <li v-if="info.instagram">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.instagram"
                    data-original-title="Google Plus"
                    ><i class="fa-brands fa-instagram"></i></a>
                </li>
                <li >
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    href="https://api.whatsapp.com/send?phone=529843105454"
                    data-original-title="Whatsapp"
                    ><i class="fa-brands fa-whatsapp"></i></a>
                </li>
                <li>
                  <a
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    href="http://m.me/100389219307939"
                    data-original-title="Messenger"
                    ><i class="fa-brands fa-facebook-messenger"></i></a>
                </li>
                <li v-if="info.linkedin">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.linkedin"
                    data-original-title="Linkedin"
                    ><i class="fa fa-linkedin"></i
                  ></a>
                </li>
                <li v-if="info.youtube">
                  <a 
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    :href="info.youtube"
                    data-original-title="Youtube"
                    ><i class="fa fa-youtube-play"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row footer-padding">
          <!-- LOGO -->
          <div class="d-block d-md-none d-lg-block col-md-2 col-lg-2 col-sm-4 col-xs-12">
            <div class="single_footer text-center">
              <router-link to="/">
                <!-- <img
                  v-if="info.logoClean"
                  class="logo"
                  :src="info.logoClean"
                  :alt="info.alt"
                /> 
                 <h3 v-else>{{ info.companyName }}</h3>-->
                <img class="logo"
                src="/img/Logo_european-blancoydorado-02.png"
                alt = "Logo-European-Quality"
                />
              </router-link>
            </div>
          </div>
          <!--- MENU -->
          <div class="col-md-6 col-lg-4 col-sm-4 col-xs-12">
            <div class="single_footer ">
              <h4>{{ $t("layout.branding.menu_title") }}</h4>
              <div class="footer_contact">
                  <ul class="col-menu">
                    <li>
                      <router-link :to="{name: 'Home'}">
                        {{ $t("layout.nav.home") }}</router-link
                      >
                    </li>
                    <!-- <li>
                      <router-link to="/desarrollos">{{
                        $t("layout.nav.developments")
                      }}</router-link>
                    </li> -->
                    <li>
                      <router-link :to="{name: 'About'}">{{
                        $t("layout.nav.about.title")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'Properties'}">{{
                        $t("layout.nav.properties")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'Agents'}">{{
                        $t("layout.nav.agents")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link :to="{name: 'Contact'}">{{
                        $t("layout.nav.contact")
                      }}</router-link>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
          <!-- CONTACT -->
          <div class="col-md-6 col-lg-4 col-sm-4 col-xs-12">
            <div class="single_footer">
              <h4>{{ $t("layout.branding.contact_title") }}</h4>
              <div class="footer_contact">
                <div class="list">
                  <div v-if="info.address">
                    <i class="fas fa-map-marker-alt"></i>
                    <span>{{ info.address }}</span>
                  </div>
                  <div v-if="info.phone">
                    <a
                          class="contact-links"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Contáctenos"
                          :href="'tel:+52' + info.phone"
                          target="_blank"
                        >
                        <i class="fa fa-phone"></i>
                          <span class="pt-0"> {{ info.phone }}</span>
                        </a>
                  </div>
                  <div  v-if="info.reserveemail">
                    <i class="fa fa-envelope"></i> 
                    <span>{{info.reserveemail}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--- END COL -->
          <div class="d-none d-md-block d-lg-none col-md-6 col-lg-2 col-sm-4 col-xs-12">
            <div class="single_footer text-center">
              <router-link to="/">
                <!-- <img
                  v-if="info.logoClean"
                  class="logo"
                  :src="info.logoClean"
                  :alt="info.alt"
                /> 
                 <h3 v-else>{{ info.companyName }}</h3>-->
                <img class="logo"
                src="/img/Logo_european-blancoydorado-02.png"
                alt = "Logo-European-Quality"
                />
              </router-link>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-2 col-sm-2 col-xs-6 wow zoomIn approb"
            style="visibility: visible; animation-name: zoomIn;"
          >
          <img class="img-fluid" src="/../../../img/EuropeanApproved.png" />
          </div>
        </div>
        <!--- END ROW -->
      </div>
      <!--- END CONTAINER -->
    </footer>
    <footer class="footer">
      <div class="container">
        <div class="row text-center">
          <div
            class="col-md-6 col-sm-6 col-xs-6 wow zoomIn"
            style="visibility: visible; animation-name: zoomIn;"
          >
            <p class="footer_copyright">{{info.companyName}}<sup>&copy;</sup>  {{year}} | {{ $t("layout.branding.copyright") }}</p>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6 wow zoomIn">
          <p class="footer_copyright">
            Powered with <i class="far fa-heart"></i> & <i class="fas fa-mug-hot"></i> by <a href="https://www.immocrm360.com.mx/" target="_blank">IMMO CRM 360<sup>&copy;</sup></a>
          </p>
        </div>
          <!--- END COL -->
        </div>
        <!--- END ROW -->
      </div>
      <!--- END CONTAINER -->
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      year: "",
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
  created() {
    console.log("create footer");
    let d = new Date();
    this.year = d.getFullYear();
  },
};
</script>

<style scoped>
.footer_contact .list div span {
    color: #dbdbdb;
    display: inline;
    font-family: montserrat,sans-serif;
    padding-top: 13px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}
.footer_contact .list div i {
    color: #ffe064;
    float: left;
    font-size: 16px;
    margin-right: 15px;
    text-align: center;
}
.logo {
  width: 100%;
}
.logo_foot {
  margin-top: 5%;
}
.contact-links {
  flex-wrap: wrap;
  overflow-wrap: anywhere;
}
@media only screen and (max-width: 991px) {
  .logo {
    width: auto;
    max-height: 150px;
  }
}
.list div{
  margin-bottom: 2rem;
}

a{
  color: #747474
}

.col-menu{
  columns: 2;
}

@media only screen and (max-width: 480px){
.menu-col {
    margin-top: 30px;
    columns: 2;
}
}

@media only screen and (max-width: 991px){
.logo {
    width: auto;
    max-height: 50vw;
}
.approb .img-fluid{
  max-width:50%;
}
.approb{
  text-align: center;
}
}
</style>
