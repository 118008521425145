<template lang="EN">
  <div >
    <HomeCarousel :items="items" />

    <!-- <SearchForm /> -->

    <div class="background pt-5">
      <div class="site-section site-section-sm bg-light">
        <div class="container">
          <p class="home-text">{{ $t("components.home_text.text1") }}</p>
          <p class="home-text">{{ $t("components.home_text.text2") }}</p>
          </div>
      </div>
      <div
        class="section-title text-center wow zoomIn"
        style="visibility: visible; animation-name: zoomIn;"
      >
        <h2>{{ $t("pages.home.title_properties") }}</h2>
        <div></div>
        
      </div>

      <div class="site-section site-section-sm bg-light">
        <div class="container">
          <div class="row mb-5 justify-content-center">
            <Preloader  v-if="!properties" />
            <PropertyCard
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel from "@/components/carousel/HomeCarousel.vue";
import Preloader from "@/components/layout/Preloader.vue";
export default {
  components: {
    HomeCarousel,
    SearchForm,
    PropertyCard,
    Preloader,
  },
  computed: {
    ...mapGetters({
      items: "_getHomeSlide",
      properties: "_getHomeProperties",
      error: "_getError",
    }),
  },
  created() {
    this.$store.dispatch("getHomeSlide");
    this.$store.dispatch("getHomeProperties");
    // Desde contactForm2 me  trae a home en inglés.
    this.$router.push({
        name: 'Home',
        params: this.$route.params,
    }).catch(()=>{});
    // console.log('Parametro home', this.$route.params)
    // console.log('Parametro lang', this.$route.params.lang)
    //condition if we doesnt recive nothing
    if (this.$route.params.lang == undefined){
      this.$i18n.locale = 'es';
    }else{
        this.$i18n.locale = this.$route.params.lang
    }
    
  },
};
</script>

<style>
/* #propImg {
  width: 100%;
  height: 100%;
} */

.background{
  background-color: #f8f9fa!important;
}

</style>
