<template>
  <div class="container privacy">
    <h2 class="title-privacy">{{ $t("pages.privacy.title") }}</h2>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle") }}</h3>
        <p>{{ $t("pages.privacy.par") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle1") }}</h3>
        <p>{{ $t("pages.privacy.par1") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle2") }}</h3>
        <p>{{ $t("pages.privacy.par2") }}</p>
        <p class="mt-4">{{ $t("pages.privacy.par2_1") }}</p>
        <p class="mt-1">{{ $t("pages.privacy.par2_2") }}</p>
        <p class="mt-1">{{ $t("pages.privacy.par2_3") }}</p>
        <p>{{ $t("pages.privacy.par2_4") }} 
            <a href="mailto:support@europeanquality.mx">{{ $t("pages.privacy.par2_email") }}</a>
        </p>
        <p>{{ $t("pages.privacy.par2_5") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle3") }}</h3>
        <p>{{ $t("pages.privacy.par3") }}</p>
        <p><b>{{ $t("pages.privacy.par3_1t") }}</b>{{ $t("pages.privacy.par3_1") }}</p>
        <p><b>{{ $t("pages.privacy.par3_2t") }}</b>{{ $t("pages.privacy.par3_2") }}</p>
        <p>{{ $t("pages.privacy.par3_2") }}</p>
        <p>{{ $t("pages.privacy.par3_3") }}</p>
        <p>{{ $t("pages.privacy.par3_4") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle4") }}</h3>
        <p>{{ $t("pages.privacy.par4") }}</p>
        <table class="table">
            <thead>
                <tr>
                    <th class="bdr-r" scope="col">{{ $t("pages.privacy.par4_tablehdr1") }}</th>
                    <th scope="col">{{ $t("pages.privacy.par4_tablehdr2") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="bdr-r pe-50">{{ $t("pages.privacy.par4_tablec1") }}</td>
                    <td>{{ $t("pages.privacy.par4_tablec2") }}</td>
                </tr>
            </tbody>
        </table>
        <p>{{ $t("pages.privacy.par4_1") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle5") }}</h3>
        <p>{{ $t("pages.privacy.par5") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle6") }}</h3>
        <p>{{ $t("pages.privacy.par6") }}</p>
        <p>{{ $t("pages.privacy.par6_1") }}</p>
        <p>{{ $t("pages.privacy.par6_2") }} <a href="mailto:support@europeanquality.mx">{{ $t("pages.privacy.par2_email") }}</a></p>
        <p>{{ $t("pages.privacy.par6_3") }} </p>
        <p>{{ $t("pages.privacy.par6_p1") }} </p>
        <p>{{ $t("pages.privacy.par6_p2") }} </p>
        <p>{{ $t("pages.privacy.par_p3") }} </p>
        <p>{{ $t("pages.privacy.par_p4") }} </p>
        <p>{{ $t("pages.privacy.par_p5") }} </p>
        <p>{{ $t("pages.privacy.par6_4") }} </p>
        <p>{{ $t("pages.privacy.par6_5") }} </p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle7") }}</h3>
        <p>{{ $t("pages.privacy.par7") }}</p>
        <p>{{ $t("pages.privacy.par7_1") }}</p>
        <p>{{ $t("pages.privacy.par7_2") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle8") }}</h3>
        <p>{{ $t("pages.privacy.par8") }}<a href="mailto:support@europeanquality.mx">{{ $t("pages.privacy.par2_email") }}; </a>{{ $t("pages.privacy.par8_1") }}</p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle9") }}</h3>
        <p>{{ $t("pages.privacy.par9") }}<a href="mailto:support@europeanquality.mx">{{ $t("pages.privacy.par2_email") }}</a></p>
    </div>
    <div class="mt-5">
        <h3>{{ $t("pages.privacy.subtitle10") }}</h3>
        <p>{{ $t("pages.privacy.par10") }}</p>
    </div>
    <div class="mt-6 text-left">
        <p>{{ $t("pages.privacy.version") }}  <br> {{ $t("pages.privacy.date") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  created() {
    //se comentó porque ahora con el /eng en la url se facilitó
    // this.$router.push({
    //     name: 'Privacy',
    //     params: this.$route.params,
    // })
   
    // this.$i18n.locale = this.$route.params;
    // console.log('Parametro privacy', this.$route.params)
   // console.log('routa', this.$route)
  },
};
</script>
<style scoped>
    .privacy .title-privacy{
        font-weight: bold;
        text-align: center !important;
    }
    .privacy h3{
        font-weight: bold;
        text-align: center !important;
        font-style: italic;
    }
    .privacy p{
        text-align: justify; 
        font-size: 14.5px;
    }
    .privacy .table{
        border-bottom-color: transparent;
        font-size: 14px;
        text-align: justify;
    }
    .privacy .bdr-r{
        border-right: 1px solid #000;
    }
    .pe-50{
        width: 50%;
    }
</style>