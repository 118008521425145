<template>
    <pre>
        <p v-html="descriptione">{{$t(`${descriptione}`)}}</p>
    </pre>
</template>

<script>
export default {
  props: {
    descriptione: { type: String, required: true },
  },
};
</script>

<style scoped>

</style>