<template>
  <div class="single_contact_one">
    <div class="contact">
      <form
        v-on:submit.prevent="sendMail"
        role="form"
        id="contact-form"
        method="post"
        enctype="multipart/form-data"
      >
        <div class="row form-group">
          <!-- NAME -->
          <div
            :class="[
              nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12',
              'mb-3',
            ]"
          >
            <input
              type="text"
              name="name"
              class="form-control"
              id="fullname"
              :placeholder="
                $t('components.contact_form.name_input_placeholder')
              "
              required="required"
              v-model="contactForm.name.value"
              :disabled="sent || sending"
            />
            <div v-if="!contactForm.name.status" class="invalid-feedback">
              Este campo es requerido.
            </div>
          </div>
          <!-- EMAIL -->
          <div
            :class="[
              nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12',
              'mb-3',
            ]"
          >
            <div>
              <input
                type="email"
                name="email"
                class="form-control"
                id="email"
                data-rule="email"
                v-model="contactForm.email.value"
                :disabled="sent || sending"
                :placeholder="
                  $t('components.contact_form.email_input_placeholder')
                "
                required="required"
              />
              <div v-if="!contactForm.email.status" class="invalid-feedback">
                Este campo es requerido y debe tener el formato correcto.
              </div>
            </div>
          </div>
          <!-- TEL -->
          <div :class="[
              nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12',
              'mb-3',
            ]">
            <div>
              <vue-tel-input
              class="form-control col-md-12"
              v-model.trim="contactForm.phone.value"
              :disabled="sent || sending"
              required="required"
              v-bind="bindTelProps"
            ></vue-tel-input>
            <div v-if="!contactForm.phone.status" class="invalid-feedback">
            Este campo es requerido.
          </div>

            </div>
            
          </div>
          <!-- MESSAGE -->
          <div v-if="nameRoute != 'DevelopmentSections'"  :class="[
            nameRoute == 'DevelopmentSections' ? 'col-md-6' : 'col-md-12',
            'mb-5',
          ]">
            <textarea
              rows="5"
              cols="30"
              name="message"
              class="form-control"
              id="message"
              :placeholder="
                $t('components.contact_form.message_input_placeholder')
              "
              required="required"
              data-rule="required"
              v-model="contactForm.message.value"
              :disabled="sent || sending"
            ></textarea>
            <div v-if="!contactForm.message.status" class="invalid-feedback">
            Este campo es requerido.
          </div>
          </div>
          
          <VueRecaptcha
            :sitekey="$store.state.CAPTCHA_KEY"
            ref="invisibleRecaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            size="invisible"
          />

          <div class="col-md-12">
            <div class="actions text-center">
              <button
                v-if="!sending"
                :disabled="sent"
                type="submit"
                value="Send message"
                name="submit"
                id="submitButton"
                class="btn btn-lg btn-contact-bg"
              >
                {{ $t("components.contact_form.button") }}
              </button>
            </div>
          </div>

          <div class="col-md-12 text-center">
            <p class="priv">{{ $t("components.contact_form.privacy_message") }}
              <router-link target="_blank" :to="{name: 'Privacy'}">
              {{ $t("routes.privacy.breadcrumb") }}
              </router-link>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import Preloader from "@/components/layout/Preloader.vue";
import services from "@/store/_services";
export default {
  components: {
    VueRecaptcha,
    Preloader,
  },
  computed: {
    ...mapState({
      URL: (state) => state.CONTACT_URL,
      PARAMS: (state) => state.API_PARAMS,
      SITE: (state) => state.URL_SITE,
    }),
    ...mapGetters({
      companyInfo: "_getInfoCompany",
      propertyInfo: "_getProperty",
      agent: "_getDataAgent",
      devInfo: "_getDevelopment",
      unitInfo: "_getActiveUnit",
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  data() {
    return {
      url: "",
      contactForm: {
        name: {
          value: "",
          status: Boolean,
        },
        phone: {
          value: "",
          status: Boolean,
        },
        email: {
          value: "",
          status: Boolean,
        },
        message: {
          value: "",
          status: Boolean,
        },
      },
      sending: false,
      sent: false,
      bindTelProps: {
        id: "phoneContact",
        mode: "international",
        defaultCountry: "MX",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        inputOptions: {
          placeholder: "9999-9999-999",
        },
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: ["MX"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 18,
        inputClasses: "form-control",
      },
    };
  },
  mounted() {
    this.url = this.SITE + this.$route.path;
    if (this.nameRoute == "DevelopmentSections") {
      this.contactForm.message.value = `HOLA! Me gustaría saber más sobre la siguiente unidad:
         <br/> <b>Nombre de la unidad:</b> "${this.unitInfo.name}"
         <br> <b>Folio de la unidad:</b> ${this.unitInfo.folio}
         <br> <b>Desarrollo:</b> ${this.devInfo.developmentNameEs}`;
    }
  },
  methods: {
    sendMail() {
      //Activate Loader
      this.sending = true;

      //Validate empty form
      for (let x in this.contactForm) {
        if (this.contactForm[x].value == "") {
          this.contactForm[x].status = false;
          this.sending = false;
          return false;
        }
      }

      //Validate email
      if (!this.validateEmail(this.contactForm.email.value)) {
        this.$swal.fire({
          icon: "error",
          text: "El formato del correo electrónico ingresado no es válido.",
          showConfirmButton: false,
          showCloseButton: true,
        });
        this.contactForm.email.status = false;
        this.sending = false;
        return false;
      } else {
        this.contactForm.email.status = true;
      }

      //Validate with captcha
      this.$refs.invisibleRecaptcha.execute();
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    onCaptchaVerified() {
      var self = this;
      let config = self.PARAMS;
      var contactType;
      var agentId;

      let data = `m=mailerContact&name=${self.contactForm.name.value}&email=${self.contactForm.email.value}&phone=${self.contactForm.phone.value}&companyName=${self.companyInfo.companyName}&companyEmail=${self.companyInfo.reserveemail}&companyAddress=${self.companyInfo.address}&companyPhone=${self.companyInfo.phone}&companyLogo=${self.companyInfo.logo}&special=${self.$store.state.ID_COMPANY}`;
      switch (this.nameRoute) {
        case "Contact":
          contactType = 1;
          agentId = "";
          data += `&origin=contact&urlSite=${self.SITE}`;
          break;
        case "Property":
          contactType = 2;
          agentId = self.propertyInfo.agent.agentId;
          data += `&folio=${self.propertyInfo.folio}&urlProp=${self.url}&agentEmail=${self.propertyInfo.agent.email}&origin=property&urlSite=${self.SITE}`;
          break;
        case "Development":
          contactType = 3;
          agentId = self.devInfo.agentId;
          data += `&folio=${self.devInfo.folio}&urlProp=${self.url}&agentEmail=${self.devInfo.agent.email}&origin=property&urlSite=${self.SITE}`;
          break;
        case "DevelopmentSections":
          contactType = 2;
          agentId = self.unitInfo.contact.soldagentid;
          self.url = `${self.SITE}/desarrollo-test/${self.devInfo.folio}`;
          data += `&folio=${self.devInfo.folio}&urlProp=${self.url}&agentEmail=${self.unitInfo.contact.email}&origin=property&urlSite=${self.SITE}`;
          break;
        case "Agent":
          contactType = 1;
          data += `&origin=contact&urlSite=${self.SITE}`;
          break;
      }
      config.body = data;
      //Send Mail

      services.sendMail(self.URL, config).then((res) => {
        if (res == 200) {
          $("#modalUnit .close").click();
          self.$swal.fire({
            icon: "success",
            title: "¡Envio completo!",
            text: `Gracias por contactarnos :)`,
            showConfirmButton: false,
            showCloseButton: true,
          });
          self.sending = false;
          self.sent = true;

          /*
                      Contact type:
                      1-Contacto gral
                      2-Propiedades
                      3-Desarrollos
                      
                      num_desarrollo
                      propertyid
                    */
          self
            .$getLocation()
            .then((coordinates) => {
              let contactInfo = {
                fullname: self.contactForm.name.value,
                email: self.contactForm.email.value,
                phone: self.contactForm.phone.value,
                message: self.contactForm.message.value,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
                contacttype: contactType,
                subContact: 217,
                contact: 25,
              };
              if (contactType == 2) {
                if (self.nameRoute == "DevelopmentSections") {
                  contactInfo.propertyid = self.unitInfo.folio;
                  contactInfo.num_desarrollo = self.devInfo.folio;
                } else {
                  contactInfo.propertyid = self.propertyInfo.folio;
                }
              }
              if (contactType == 3)
                contactInfo.num_desarrollo = self.devInfo.folio;
              if (contactType != 1) contactInfo.soldagentid = agentId;
              self.$store.dispatch("setNewContact", contactInfo);
            })
            .catch(() => {
              let contactInfo = {
                fullname: self.contactForm.name.value,
                email: self.contactForm.email.value,
                phone: self.contactForm.phone.value,
                message: self.contactForm.message.value,
                latitude: 0,
                longitude: 0,
                contacttype: contactType,
                subContact: 217,
                contact: 25,
              };
              if (contactType == 2) {
                if (self.nameRoute == "DevelopmentSections") {
                  contactInfo.propertyid = self.unitInfo.folio;
                  contactInfo.num_desarrollo = self.devInfo.folio;
                } else {
                  contactInfo.propertyid = self.propertyInfo.folio;
                }
              }
              if (contactType == 3)
                contactInfo.num_desarrollo = self.devInfo.folio;
              if (contactType != 1) contactInfo.soldagentid = agentId;
              self.$store.dispatch("setNewContact", contactInfo);
            });
        } else {
          self.$swal.fire({
            icon: "error",
            text: "No se pudo completar el envio del formulario.",
            showConfirmButton: false,
            showCloseButton: true,
          });
        }
      });
    },
    onCaptchaExpired() {
      this.$swal.fire({
        icon: "error",
        text: "No se pudo completar el envio del formulario.",
        showConfirmButton: false,
        showCloseButton: true,
      });
      this.sending = false;
      this.$refs.invisibleRecaptcha.reset();
    },
  },
};
</script>

<style scoped>
.form-control {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
  color: #333;
  height: 50px;
  padding-left: 10px;
  transition: all 0.2s ease 0s;
  margin: 0 !important;
}

.form-group {
  margin: 5px 0;
}
.priv{
  font-size: 13px;
}
textarea {
  float: right;
  margin: 8px 0;
}

.vue-tel-input {
  background-color: #fff;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  height: 50px;
}
.vue-tel-input:focus-within {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #fff !important;
}

button:hover {
  background: transparent !important;
  color: #252b5f;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}

button {
  background: #252b5f!important;
  border: 1px solid #252b5f !important;
  color: #fff;
  font-size: 14px;
  padding: 10px 30px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
}
a {
  color: #252b5f;
}

.captcha {
  font-size: 14px;
}
</style>
