<template>
			<div class="container">
				<div class="row">				
					<div class="col-md-6 col-md-offset-3 col-sm-12 col-xs-12 text-center">
						<div class="error_page">
							<h1><span v-for="(number, i) in splitCode" :key="number" :index="i">{{number}}</span></h1>
							<h2>{{$t(`components.error_section.${code}.title`)}}</h2>
                            <br>
							<router-link to="/" class="btn-contact-bg">{{$t("layout.not_found.go_home")}}</router-link>
						</div>
					</div><!--- END COL -->	
				</div><!--- END ROW -->
			</div><!--- END CONTAINER -->
</template>

<script>
export default {
  props:{
    code:{
      typeo:String,
      require:true
    }
  },
  computed:{
    splitCode(){
      return this.code.split("")
    }
  }
}
</script>